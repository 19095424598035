import React, { useState, useEffect } from 'react';
import styles from '../styles/games.module.scss';
import {FiChevronLeft, FiChevronRight} from 'react-icons/fi';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const categories = {
  0: { en_title: "Sport", ar_title: "رياضة" },
  1: { en_title: "Arcade", ar_title: "مغامرة" },
  2: { en_title: "Action", ar_title: "أكشن" },
  3: { en_title: "Puzzles", ar_title: "ألغاز" },
  4: { en_title: "Entertainment", ar_title: "ترفيه" },
  5: { en_title: "Fun", ar_title: "مرح" }
};

export default function Games() {

  const { i18n } = useTranslation();
  let { cat } = useParams();
  const navigate = useNavigate();
  const [games, setGames] = useState([]);

  useEffect(() => {
    fetch('/games.json')
      .then(response => response.json())
      .then(data =>{
        setGames(data[cat]);
      })
      .catch((err)=>{
        console.log(err)
      })
  }, []);

  return (
    <div className={styles.container} >
      <div className={styles.header} onClick={() => navigate(-1)}  >
        {
          i18n.language === "ar"
            ? <FiChevronRight size={32} color='#fff' className={styles.backBtn} />
            : <FiChevronLeft size={32} color='#fff' className={styles.backBtn} />
        }
        <p>{categories[cat][`${i18n.language}_title`]}</p>
      </div>
      <div className={styles.gamesWrapper} >
        { games &&
          games.map(it=>(
            <div key={it.id} className={styles.game} >
              <Link to={"/game/"} state={{gameUrl: it.gameUrl}} className={styles.imgWrapper} >
                <img src={it.imgurl} />
              </Link>
              <p>{it[`name_${i18n.language}`]}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}
