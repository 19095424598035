import React from 'react';
import styles from "../styles/home.module.scss";
import { Link } from 'react-router-dom';

import sportImg from '../files/Sport.png';
import arcadeImg from '../files/Arcade.png';
import actionImg from '../files/Action.png';
import puzzlesImg from '../files/Puzzles.png';
import entertainmentImg from '../files/Entertainment.png';
import funImg from '../files/Fun.png';
import {useTranslation} from "react-i18next";

const categories = [
  {id: 0, en_title: "Sport", ar_title: "رياضة", image: sportImg},
  {id: 1, en_title: "Arcade", ar_title: "مغامرة", image: arcadeImg},
  {id: 2, en_title: "Action", ar_title: "أكشن", image: actionImg},
  {id: 3, en_title: "Puzzles", ar_title: "ألغاز", image: puzzlesImg},
  {id: 4, en_title: "Entertainment", ar_title: "ترفيه", image: entertainmentImg},
  {id: 5, en_title: "Fun", ar_title: "مرح", image: funImg},
];

export default function Home() {

  const { i18n } = useTranslation();

  return (
    <div className={styles.homeContainer} >
      {/* <iframe src="/games/egggo/index.html" title="My Game" style={{width: '100vw', height: '90vh'}} ></iframe> */}
      <div className={styles.categoriesWrapper} >
        {
          categories.map(it=>
            <Link to={"/games/"+it.id} key={it.id} className={styles.category} >
              <div className={styles.categoryImg} >
                <img src={it.image} />
              </div>
              <p>{it[`${i18n.language}_title`]}</p>
            </Link>
          )
        }
      </div>
    </div>
  )
}
